import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Button,
  Card,
  Grid,
  Container,
  Modal,
  Typography,
  IconButton,
  TextField,
  LinearProgress,
  FormControl,
  FormControlLabel,
  CircularProgress,
  Checkbox,
  Divider,
  MobileStepper,
  Backdrop,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  useFirestore,
  useStorage,
  useUser,
  useAuth,
  useFunctions,
  useSigninCheck,
  preloadFunctions,
} from "reactfire";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import MVLogo from "../assets/MV_logo.png";

// import zip from "jszip";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { Parser } from "json2csv";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SignInForm = () => {
  const auth = useAuth;

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: auth.EmailAuthProvider.PROVIDER_ID,
        disableSignUp: true,
        adminEmail: "info@email.com",
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
    adminRestrictedOperation: {
      status: true,
    },
  };

  return (
    <Card title="Sign-in form">
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
    </Card>
  );
};

export default function Backend() {
  const [rows, setRows] = useState([]);
  const { status, data: signinResult } = useSigninCheck();
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [downloadCount, setDownloadCount] = useState(0);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const usersRef = useFirestore().collection("users");
  const storage = useStorage();

  let functions = useFunctions();
  functions.useEmulator("localhost", 5001);

  const fields = [
    {
      label: "id",
      value: "id",
    },
    {
      label: "First Name",
      value: "firstname",
    },
    {
      label: "Family Name",
      value: "familyname",
    },
    {
      label: "Age",
      value: "age",
    },
    {
      label: "Gender",
      value: "gender",
    },
    {
      label: "Ethnicity",
      value: "ethnicity",
    },
    {
      label: "Other ethnicity",
      value: "ethnicityText",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Where do you live?",
      value: "location",
    },
    {
      label: "Consent 0",
      value: "consent0",
    },
    {
      label: "Consent 1",
      value: "consent1",
    },
    {
      label: "Consent 2",
      value: "consent2",
    },
    {
      label: "Consent 3",
      value: "consent3",
    },
    {
      label: "Consent 4",
      value: "consent4",
    },
    {
      label: "Consent 5",
      value: "consent5",
    },
    {
      label: "Consent 6",
      value: "consent6",
    },
    {
      label: "Consent 7",
      value: "consent7",
    },
    {
      label: "Consent 8",
      value: "consent8",
      default: "N/A",
    },
    {
      label: "Parent/Carer consent 0",
      value: "parentConsent0",
    },
    {
      label: "Parent/Carer consent 1",
      value: "parentConsent1",
    },
    {
      label: "Parent/Carer consent 2",
      value: "parentConsent2",
    },
    {
      label: "Parent/Carer consent 3",
      value: "parentConsent3",
    },
    {
      label: "Parent/Carer consent 4",
      value: "parentConsent4",
    },
    {
      label: "Parent/Carer consent 5",
      value: "parentConsent5",
    },
    {
      label: "Parent/Carer consent 6",
      value: "parentConsent6",
    },
    {
      label: "Parent/Carer consent 7",
      value: "parentConsent7",
    },
    {
      label: "Parent/Carer first name",
      value: "parentfirstname",
    },
    {
      label: "Parent/Carer family Name",
      value: "parentfamilyname",
    },
    {
      label: "Parent/Carer email",
      value: "parentemail",
    },
  ];

  const json2csvParser = new Parser({ fields });

  const [currentSelection, setCurrentSelection] = useState([]);

  const columns = [
    { field: "id", headerName: "Id", width: 200 },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params) => (
        <>
          <p variant="outlined" color="primary" size="small">
            {moment(params.value.seconds * 1000).format("DD/MM/YYYY HH:mm")}
          </p>
        </>
      ),
    },
    { field: "firstname", headerName: "First Name", width: 200 },
    { field: "familyname", headerName: "Family Name", width: 200 },
    { field: "age", headerName: "Age", width: 120 },
    { field: "gender", headerName: "Gender", width: 120 },
    { field: "ethnicity", headerName: "Ethnicity", width: 300 },
    {
      field: "location",
      headerName: "Where do you live?",
      width: 120,
    },
    // { field: "uploaded", headerName: "uploaded", width: 300 },
    // { field: "downloaded", headerName: "downloaded" },
    {
      field: "downloadlink",

      width: 300,
      // flex: 1,
      headerName: "Recording",
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <>
          {/* <a target="_blank" href={params.value}> */}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={async () => {
              let filename = `${params.row.id}.mp3`;

              try {
                const newRef = await storage
                  .ref("audio")
                  .child(filename)
                  .getDownloadURL();
                window.open(newRef, "_blank");

                console.log(newRef);
              } catch (error) {
                alert("Error! " + error);
              }
            }}
          >
            play
          </Button>
          {/* </a> */}
        </>
      ),
    },
  ];

  const urlToPromise = (url) => {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  const zipAndDownload = async (audioFiles, data) => {
    setOpen(true);

    try {
      const newRef = storage.ref("audio");

      const zip = new JSZip();

      //let items = (await newRef.listAll()).items;

      const csv = json2csvParser.parse(data);

      console.log(csv);
      zip.file("data.csv", csv);
      for (const item of audioFiles) {
        let tempLink = await storage.ref(item.fullPath).getDownloadURL();

        //JSZip.file(item.name, files[file]);
        zip.file(item.name, urlToPromise(tempLink));
      }
      zip
        .generateAsync({ type: "blob" }, function updateCallback(metadata) {
          var msg = "progression : " + metadata.percent.toFixed(2) + " %";
          if (metadata.currentFile) {
            msg += ", current file = " + metadata.currentFile;
          }
          // setDownloadCount(metadata.percent.toFixed(2) + " %");
          // console.log(metadata.percent.toFixed(2));
        })
        .then((content) => {
          saveAs(content, "Recording-" + Date.now() + ".zip");
        });
    } catch (error) {
      alert("error downloading files" + error);
    }

    setOpen(false);
  };

  useEffect(() => {
    const loadItems = async () => {
      //load users
      let tempEntry = [];

      const userItems = await usersRef.get();
      userItems.docs.map((item, index) => {
        console.log(item);
        tempEntry.push({ ...item.data(), id: item.id });
      });
      console.log(tempEntry);
      // console.log(userItems.docs[0].data());
      // return;

      // let items = (await storageRef.listAll()).items;
      // let tempEntry = [];

      // console.log("items ", items);

      // items.map((item, index) => {
      //   tempEntry.push({
      //     id: index,
      //     filename: item.name,
      //     actions: "",
      //   });
      // });

      setRows(tempEntry);
      // setRows(items);
    };
    if (auth.currentUser) {
      loadItems();
    }
  }, [auth.currentUser]);

  //load data
  if (status === "loading") {
    return (
      <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  const { signedIn, user } = signinResult;

  if (signedIn === false) {
    return <SignInForm />;
  } else {
    return (
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center"
        style={{ padding: 20 }}
      >
        <div style={{ position: "absolute", right: 30, top: 40, margin: 20 }}>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                auth.signOut().then(() => console.log("signed out"));
              }}
            >
              logout
            </Button>
          </Grid>
        </div>
        <Grid item style={{ marginBottom: 40 }}>
          <img src={MVLogo} width={400} alt="" />
        </Grid>

        <Grid item>
          <Typography
            component="h3"
            variant="h3"
            align="center"
            color="textPrimary"
            gutterBottom
          ></Typography>
        </Grid>

        <Grid item style={{ height: 600, width: "100%" }}>
          <DataGrid
            disableSelectionOnClick
            rows={rows}
            onSelectionModelChange={(params) => {
              // console.log(params);
              setCurrentSelection(params.selectionModel);
            }}
            columns={columns}
            autoHeight={true}
            autoPageSize={true}
            // pageSize={7}
            checkboxSelection
          />
        </Grid>

        <Grid item style={{ marginTop: 50 }}>
          <Grid container spacing={3}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={currentSelection.length == 0}
                onClick={async () => {
                  try {
                    let data = [];
                    let audioFiles = [];
                    let newRef = storage.ref("audio");

                    // const zip = new JSZip();

                    //let items = (await newRef.listAll()).items;

                    for (const item of currentSelection) {
                      data.push(_.find(rows, { id: item }));

                      let file = await newRef.child(item + ".mp3");
                      audioFiles.push(file);
                    }
                    zipAndDownload(audioFiles, data);
                    // console.log(data);
                  } catch (error) {}
                }}
              >
                Download selected
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                // disabled={currentSelection.length == 0}
                onClick={() => {
                  console.log(currentSelection);
                }}
              >
                Download new
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={async () => {
                  // try {
                  const newRef = storage.ref("audio");

                  // const zip = new JSZip();

                  let items = (await newRef.listAll()).items;
                  console.log(items);
                  zipAndDownload(items, rows);
                  //   const csv = json2csvParser.parse(rows);

                  //   console.log(csv);
                  //   zip.file("data.csv", csv);
                  //   for (const item of items) {
                  //     let tempLink = await storage
                  //       .ref(item.fullPath)
                  //       .getDownloadURL();

                  //     //JSZip.file(item.name, files[file]);
                  //     zip.file(item.name, urlToPromise(tempLink));
                  //   }
                  //   zip.generateAsync({ type: "blob" }).then((content) => {
                  //     saveAs(content, "Recording-" + Date.now() + ".zip");
                  //   });
                  // } catch (error) {
                  //   alert("error downloading files" + error);
                  // }

                  // let files = functions.httpsCallable("getfiles");
                  // files({ text: "messageText" }).then((result) => {
                  //   // Read result of the Cloud Function.
                  //   var sanitizedMessage = result.data;
                  //   console.log(sanitizedMessage);
                  // });
                  // console.log(files);

                  // return;

                  // let getfiles = functions.httpsCallable("getfiles");

                  // getfiles({ text: "messageText" })
                  //   .then((result) => {
                  //     // Read result of the Cloud Function.
                  //     var sanitizedMessage = result.data;
                  //     console.log(sanitizedMessage);
                  //   })
                  //   .catch((error) => {
                  //     // Getting the Error details.
                  //     var code = error.code;
                  //     var message = error.message;
                  //     var details = error.details;
                  //     console.log(error);

                  //     // ...
                  //   });
                  // .then((res) => {
                  //   console.log(res);
                  // })
                  // .catch((err) => console.log(err));
                }}
              >
                Download All
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={handleClose}
        >
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item> Downloaing...please wait...</Grid>
          </Grid>
        </Backdrop>
      </Grid>
    );
  }
}
