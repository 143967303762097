import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Button,
  Card,
  Grid,
  Container,
  Modal,
  Typography,
  IconButton,
  TextField,
  LinearProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Hidden,
  FormControlLabel,
  Checkbox,
  MobileStepper,
  FormLabel,
  FormHelperText,
  Link,
} from "@material-ui/core";

import Timer from "react-compound-timer";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";

// import _ from "lodash";
import MicIcon from "@material-ui/icons/Mic";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import MicRecorder from "mic-recorder-to-mp3";
import { MapOutlined } from "@material-ui/icons";

import { useFirestore, useStorage } from "reactfire";
import ReactPlayer from "react-player";

// assets
import QuestionAudio from "../assets/voices/over16/Over 16 Q1.mp3";
import rawQuestionData from "../assets/questions.json";
import rawConsentData from "../assets/consent.json";
import MVLogo from "../assets/MV_logo.png";
import DirectionPicture from "../assets/picture.png";
import MapImage from "../assets/map.png";
import BtnUnder from "../assets/BTN_start_U16.png";
import BtnOver from "../assets/BTN_start_16.png";
import BtnNext from "../assets/next.png";
import BtnPrevious from "../assets/back.png";
import LogoMMW from "../assets/MMU Logo.png";
import RecordIndicator from "../assets/record.png";
import BtnRecord from "../assets/BTN_record.png";
import LogoUKRI from "../assets/UKRI Logo.png";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#f5d120",
  },
}))(LinearProgress);

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

export default function Experience() {
  const [questions, setQuestions] = useState([]);
  const [consentQuestions, setConsentQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestion] = useState(0);
  const [experienceState, setExperienceState] = useState(0);
  const [formState, setFormState] = useState(0);
  const [isRecoring, setIsRecording] = useState(false);
  const [isLastpage, setIsLastpage] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [isLegalAge, setIsLegalAge] = useState(true);
  const [open, setOpen] = useState(true);

  const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);
  const classes = useStyles();

  const usersRef = useFirestore().collection("users");
  const serverTime = useFirestore.FieldValue;
  const storage = useStorage();

  const questionAudioPlayerRef = useRef(null);
  const [currentQuestionAudio, setCurrentQuestionAudio] = useState(
    "voices/over16/Over 16 Q1.mp3"
  );
  const recordingTimerRef = useRef();
  const [isQuestionAudioPlaying, setIsQuestionAudioPlaying] = useState(false);

  //init
  useEffect(() => {
    // fetch("https://jsonplaceholder.typicode.com/posts/")
    //   .then((response) => response.json())
    //   .then((json) => {
    //     let _tempQuestions = _.slice(json, 0, 12);
    //     setQuestions(_tempQuestions);
    //     console.log(_tempQuestions);
    //   });
  }, []);

  const handleHelpModal = () => {
    setOpenHelpModal(false);
  };

  const playAudio = (audioPath) => {
    questionAudioPlayerRef.current.seekTo(0);
    setIsQuestionAudioPlaying(false);

    setCurrentQuestionAudio(audioPath);
    setIsQuestionAudioPlaying(true);
  };

  const updateAudio = (index) => {
    questionAudioPlayerRef.current.seekTo(0);
    setIsQuestionAudioPlaying(false);

    let audioPath = "";
    if (isLegalAge) {
      audioPath = `voices/over16/Over 16 Q${index + 1}.mp3`;
    } else {
      audioPath = `voices/under16/Under 16 Q${index + 1}.mp3`;
    }

    // console.log("courage - test");
    setCurrentQuestionAudio(audioPath);
  };

  const updateQuestionIndex = (num) => {
    let nextIndex = currentQuestionIndex + num;
    if (isLastpage) nextIndex = questions.length - 1;

    let clampIndex = Math.min(questions.length - 1, Math.max(0, nextIndex));
    setCurrentQuestion(clampIndex);

    const lastpage = nextIndex == questions.length;

    if (lastpage) {
      playAudio("voices/Continue.mp3");
    } else {
      updateAudio(clampIndex);
    }

    setIsLastpage(lastpage);
    setIsQuestionAudioPlaying(true);
  };

  const setFirstQuestion = () => {
    updateAudio(0);
    setCurrentQuestion(0);
  };

  // const onFormSubmit = (data) => {
  //   console.log("courage", data);
  //   setExperienceState(2);
  //   setUserInfo(data);
  // };

  // const ValidateForm = () => {
  //   setExperienceState(2);
  // };

  const StartRecording = () => {
    recorder
      .start()
      .then(() => {
        // something else
        recordingTimerRef.current.start();
        setExperienceState(3);
        setIsRecording(true);
        console.log("start rec...");
      })
      .then(() => {
        console.log("start audio...");
        // updateQuestionIndex(0);
        setFirstQuestion();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const StopRecording = () => {
    recordingTimerRef.current.stop();

    // Once you are done singing your best song, stop and get the mp3.

    recorder
      .stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        // do what ever you want with buffer and blob
        // Example: Create a mp3 file and play

        //Create document firestore
        let alldata = { ...userInfo };
        for (var prop in alldata) {
          if (alldata[prop] === undefined) {
            alldata[prop] = "";
          }
        }

        // let serverdate = useFirestore.FieldValue.serverTimestamp();
        // create initial entry
        let initEntry = await usersRef.add({
          ...alldata,
          date: serverTime.serverTimestamp(),
          uploaded: false,
          downloaded: false,
        });

        console.log(initEntry);
        const filename = initEntry.id + ".mp3";
        const file = new File(buffer, filename, {
          type: blob.type,
          lastModified: Date.now(),
        });

        const newRef = await storage.ref("audio").child(filename);
        const uploadTask = await newRef.put(file);
        setUploadCompleted(true);

        //const downloadlink = await uploadTask.ref.getDownloadURL();

        await usersRef
          .doc(initEntry.id)
          .update({ downloadlink: "", uploaded: true });

        console.log(uploadTask);
        // docRef.add(userInfo).then((res) => {
        //   console.log("res ->", res.id);
        //   const filename = res.id + ".mp3";
        //   const file = new File(buffer, filename, {
        //     type: blob.type,
        //     lastModified: Date.now(),
        //   });

        //   console.log(file);
        //   // const player = new Audio(URL.createObjectURL(file));
        //   // player.play();

        //   const newRef = storage.ref("audio").child(filename);
        //   const uploadTask = newRef.put(file);

        //   uploadTask.then((res) => {
        //     console.log("upload complete");
        //     setUploadCompleted(true);
        //   });
        // });
      })
      .catch((e) => {
        alert("We could not retrieve your message");
        console.log(e);
      });

    setIsRecording(false);
  };

  const loadQuestion = (questionKey, setExp = true) => {
    let _questions = rawQuestionData[questionKey];
    // let _trimmed = _.slice(_questions, 0, 2);

    setIsLegalAge(questionKey == "over16");
    setQuestions(_questions);
    setConsentQuestions(rawConsentData[questionKey]);
    if (setExp) {
      setExperienceState(1);
    }
  };

  const StartQuestion = () => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 1, 0.5)",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Grid
            item
            container
            xs={11}
            sm={6}
            md={4}
            spacing={3}
            direction="row"
            justify="center"
            alignItems="flex-start"
            tabIndex={100}
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              borderRadius: 10,
              padding: 20,
              // height: 250,
            }}
            // className={classes.paper}
          >
            <Grid item>
              <Typography
                variant="h4"
                align="center"
                // color="textSecondary"
                paragraph
              >
                TIPS
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                align="left"
                // color="textSecondary"
                paragraph
              >
                <li>
                  Click the <b>"NEXT"</b> button after answering a question.
                </li>
                <br />
                <li>
                  If you'd like to hear the question again, click the{" "}
                  <b>"REPEAT QUESTION"</b> button.
                </li>
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: 20 }} align="center">
              <Button
                variant="contained"
                color="primary"
                style={{ height: 60, fontSize: "1.2rem" }}
                // endIcon={<VolumeUpIcon />}
                onClick={() => {
                  // if (isQuestionAudioPlaying) {
                  //   questionAudioPlayerRef.current.seekTo(0);
                  // }
                  handleClose();
                  console.log("close");
                  setIsQuestionAudioPlaying(true);
                }}
              >
                Start
              </Button>
            </Grid>
          </Grid>
        </Modal>
      </div>
    );
  };

  const QuestionsTemplate = () => {
    return (
      <>
        <StartQuestion />

        <Grid container justify="center" direction="column">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginTop: 20, marginBottom: 20, minHeight: 300 }}
          >
            <Grid item>
              {!isLastpage ? (
                <Grid container direction="column" justify="center">
                  <Grid
                    item
                    container
                    justify="center"
                    direction="row"
                    alignItems="center"
                  >
                    {/* Large screen */}
                    <Hidden smDown>
                      <Grid item xs={3} align="center">
                        <PreviousButtonComponent />
                      </Grid>
                    </Hidden>

                    <Grid item md={6} xs={10}>
                      <Typography
                        align="center"
                        variant="h5"
                        // color="textSecondary"
                        style={{ fontWeight: "bold" }}
                        paragraph
                      >
                        {questions.length > 0
                          ? questions[currentQuestionIndex]
                          : ""}
                      </Typography>
                    </Grid>
                    {/* Large screen */}
                    <Hidden smDown>
                      <Grid item xs={3} align="center">
                        <NextButtonComponent />
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Grid item style={{ marginTop: 20 }} align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ height: 60, fontSize: "1.2rem" }}
                      // endIcon={<VolumeUpIcon />}
                      onClick={() => {
                        if (isQuestionAudioPlaying) {
                          questionAudioPlayerRef.current.seekTo(0);
                        }
                        setIsQuestionAudioPlaying(true);
                      }}
                    >
                      repeat question
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                >
                  <Grid item align="center" md={8} xs={10}>
                    <Typography
                      variant="h6"
                      align="center"
                      // color="textSecondary"
                      paragraph
                    >
                      Thanks for answering my questions.
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      // color="textSecondary"
                      paragraph
                    >
                      There’s one more thing I’d like to ask you to do, but only
                      if you have time. It will take another 3 minutes. If you’d
                      prefer to finish now, that’s absolutely fine.
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginTop: 50 }}>
                    <Grid container spacing={3} justify="center">
                      <Grid item>
                        <Button
                          size="large"
                          variant="contained"
                          style={{ width: 200, height: 60, fontSize: "1.2rem" }}
                          color="secondary"
                          // disabled={currentQuestionIndex == questions.length - 1}
                          onClick={() => {
                            StopRecording();
                            setExperienceState(5);
                            playAudio("voices/Thanks.mp3");
                          }}
                        >
                          Finish
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: "#59af44",
                            width: 200,
                            height: 60,
                            fontSize: "1.2rem",
                          }}
                          onClick={() => {
                            setExperienceState(4);
                            playAudio("voices/Map.mp3");
                          }}
                        >
                          continue
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!isLastpage && (
          <Hidden mdUp>
            <Grid
              container
              justify="center"
              spacing={3}
              style={{ marginBottom: 40 }}
            >
              <Grid item align="center">
                <PreviousButtonComponent />
              </Grid>
              <Grid item align="center">
                <NextButtonComponent />
              </Grid>
            </Grid>
          </Hidden>
        )}
        {/* 
        <Grid container spacing={2} justify="center">


        </Grid> */}
        <Grid container justify="center" direction="column">
          <Grid item>
            <MobileStepper
              variant="dots"
              steps={questions.length}
              position="static"
              activeStep={currentQuestionIndex}
              style={{ justifyContent: "center", backgroundColor: "unset" }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {currentQuestionIndex + 1} / {questions.length}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const FormTemplate = () => {
    let curForm = null;
    switch (formState) {
      case 0:
        curForm = <UserInfoForm />;
        break;
      case 1:
        curForm = <ConsentForm />;

        break;
      case 2:
        curForm = <ParentConsentForm />;

        break;
      default:
        break;
    }
    return (
      <Grid container justify="center">
        {curForm}
      </Grid>
    );
  };

  const ParentConsentForm = () => {
    const { handleSubmit, control } = useForm();

    const FormSubmit = (data) => {
      console.log(data);
      let dataUpdate = { ...userInfo, ...data };

      setUserInfo(dataUpdate);
      setExperienceState(2);
    };

    return (
      <Grid item xs={12} md={8}>
        <form onSubmit={handleSubmit(FormSubmit)}>
          <Grid container direction="column" spacing={0}>
            <Grid item align="center">
              <Typography
                // color="textSecondary"
                variant="h6"
                style={{ marginTop: 50, marginBottom: 20 }}
              >
                {rawConsentData["parent"].title}
              </Typography>
            </Grid>

            {/* dynamic */}
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Controller
                      name={"parentconsent0"}
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label={
                    <div>
                      I confirm that I have read the{" "}
                      <Link
                        href="http://www.manchestervoices.org/wp-content/uploads/2021/08/Virtual-Accent-Van-Information-Sheet.pdf"
                        target="_blank"
                        rel="noopener"
                        underline="always"
                        onClick={() => {}}
                      >
                        information sheet
                        <OpenInNewIcon color="primary" fontSize="small" />
                      </Link>{" "}
                      for the above study. I have had the opportunity to
                      consider the information and ask questions about the
                      procedure.
                    </div>
                  }
                />
              </FormControl>
            </Grid>
            {rawConsentData["parent"].consents.map((el, index) => (
              <Grid item key={index}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Controller
                        name={"parentConsent" + (index + 1)}
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            checked={value}
                            onChange={onChange}
                            color="primary"
                          />
                        )}
                      />
                    }
                    label={el}
                  />
                </FormControl>
              </Grid>
            ))}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Typography>Your first name *</Typography>

                  <Controller
                    name="parentfirstname"
                    control={control}
                    defaultValue=""
                    // rules={{ required: "First name required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        error={!!error}
                        id="parentfirstname"
                        // label="Where do you live?"
                        name="parentfirstname"
                        onChange={onChange}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Typography>Your family name *</Typography>

                  <Controller
                    name="parentfamilyname"
                    control={control}
                    defaultValue=""
                    // rules={{ required: "First name required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        error={!!error}
                        id="parentfamilyname"
                        // label="Where do you live?"
                        name="familyname"
                        onChange={onChange}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <Typography>Your email *</Typography>

                <Controller
                  name="parentemail"
                  control={control}
                  defaultValue=""
                  // rules={{ required: "First name required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      error={!!error}
                      id="parentemail"
                      // label="Where do you live?"
                      name="parentemail"
                      onChange={onChange}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item align="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              style={{ fontSize: "1.2rem" }}
            >
              Next
            </Button>
          </Grid>
        </form>
      </Grid>
    );
  };

  const ConsentForm = () => {
    const { handleSubmit, control } = useForm();

    const FormSubmit = (data) => {
      console.log(data);
      let dataUpdate = { ...userInfo, ...data };
      // (2);
      setUserInfo(dataUpdate);
      if (isLegalAge) {
        setExperienceState(2);
      } else {
        setFormState(2);
      }
    };

    return (
      <Grid item xs={12} md={8}>
        <form onSubmit={handleSubmit(FormSubmit)}>
          <Grid container direction="column">
            <Grid item align="center">
              <Typography
                // color="textSecondary"
                variant="h6"
                style={{ marginTop: 50, marginBottom: 20 }}
              >
                {consentQuestions.title}
              </Typography>
            </Grid>

            {/* dynamic */}
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Controller
                      name={"consent0"}
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label={
                    <div>
                      I confirm that I have read the{" "}
                      <Link
                        href="http://www.manchestervoices.org/wp-content/uploads/2021/08/Virtual-Accent-Van-Information-Sheet.pdf"
                        target="_blank"
                        rel="noopener"
                        underline="always"
                        onClick={() => {}}
                      >
                        information sheet
                        <OpenInNewIcon color="primary" fontSize="small" />
                      </Link>{" "}
                      for the above study. I have had the opportunity to
                      consider the information and ask questions about the
                      procedure.
                    </div>
                  }
                />
              </FormControl>
            </Grid>

            {consentQuestions.consents.map((el, index) => {
              return (
                <Grid item key={index}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <FormControlLabel
                      control={
                        <Controller
                          name={"consent" + (index + 1)}
                          control={control}
                          defaultValue={false}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              checked={value}
                              onChange={onChange}
                              color="primary"
                            />
                          )}
                        />
                      }
                      label={<div>{el}</div>}
                    />
                  </FormControl>
                </Grid>
              );
            })}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Typography>First name *</Typography>

                  <Controller
                    name="firstname"
                    control={control}
                    defaultValue=""
                    // rules={{ required: "First name required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        error={!!error}
                        id="firstname"
                        // label="Where do you live?"
                        name="firstname"
                        onChange={onChange}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Typography>Family name *</Typography>

                  <Controller
                    name="familyname"
                    control={control}
                    defaultValue=""
                    // rules={{ required: "First name required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        error={!!error}
                        id="familyname"
                        // label="Where do you live?"
                        name="familyname"
                        onChange={onChange}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <Typography>Email *</Typography>

                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  // rules={{ required: "First name required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      error={!!error}
                      id="email"
                      // label="Where do you live?"
                      name="email"
                      onChange={onChange}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item align="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              style={{ fontSize: "1.2rem" }}
            >
              Next
            </Button>
          </Grid>
        </form>
      </Grid>
    );
  };

  const PreviousButtonComponent = () => {
    return (
      <Button
        // size="large"
        // variant="outlined"
        // color="primary"
        disabled={currentQuestionIndex == 0}
        onClick={() => {
          updateQuestionIndex(-1);
        }}
        style={{ opacity: currentQuestionIndex == 0 ? 0.1 : 1 }}
      >
        <img src={BtnPrevious} />
      </Button>
    );
  };

  const NextButtonComponent = () => {
    return (
      <Button
        // size="large"
        // variant="outlined"
        // color="primary"
        disabled={isLastpage}
        onClick={() => {
          updateQuestionIndex(1);
        }}
        style={{ opacity: isLastpage ? 0.1 : 1 }}
      >
        <img src={BtnNext} />
      </Button>
    );
  };

  const UserInfoForm = () => {
    const {
      handleSubmit,
      control,
      watch,
      formState: { errors },
    } = useForm({ defaultValues: { age: isLegalAge ? "18-25" : "Under 18" } });
    const showEthnicityText = watch("ethnicity");
    const showGenderText = watch("gender");
    const selectedAge = watch("age");
    // const watchFields = watch(["gender", ""]); // you can also target specific fields by their names

    const FormSubmit = (data) => {
      if (selectedAge == "Under 18") {
        loadQuestion("under16", false);

        console.log("loading under 16");
      } else {
        console.log("loading over 16");
        loadQuestion("over16", false);
      }
      console.log(data);

      // setExperienceState(2);
      setUserInfo(data);
      setFormState(1);
    };

    return (
      <Grid item xs={12} md={8}>
        <form onSubmit={handleSubmit(FormSubmit)}>
          <Grid container direction="column" spacing={1}>
            <Grid item align="center">
              <Typography
                // color="textSecondary"
                variant="h6"
                style={{ marginTop: 50, marginBottom: 20 }}
              >
                Your details
              </Typography>
            </Grid>
            {Object.keys(errors).length > 0 && (
              <Grid item align="center">
                <Typography
                  // color="red"
                  // variant="h6"
                  style={{ color: "red" }}
                >
                  Please answer the required (*) fields.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                {/* <InputLabel id="age-label">Age</InputLabel> */}
                <Typography style={{}}>Age*</Typography>
                <Controller
                  name="age"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      // label="Age"
                      labelId="age-label"
                      id="age"
                      defaultValue={isLegalAge ? "18-25" : "Under 18"}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    >
                      <MenuItem value="Under 18">Under 18</MenuItem>
                      <MenuItem value="18-25">18-25</MenuItem>
                      <MenuItem value="26-45">26-45</MenuItem>
                      <MenuItem value="46-65">46-65</MenuItem>
                      <MenuItem value="66+">66+</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                {/* <InputLabel id="gender-label">Gender</InputLabel> */}
                <Typography>Gender*</Typography>

                <Controller
                  name="gender"
                  rules={{
                    required: "This field is required.",
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      labelId="gender-label"
                      id="gender"
                      onChange={onChange}
                      defaultValue="empty"
                      error={!!error}
                    >
                      <MenuItem value="empty" disabled>
                        -------
                      </MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="neither">
                        Neither. My gender is ....
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            {showGenderText == "neither" && (
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Typography>Gender</Typography>

                  <Controller
                    name="genderText"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="genderText"
                        // label="Where do you live?"
                        name="genderText"
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                {/* <InputLabel id="ethnicity-label">Ethnicity</InputLabel> */}
                <Typography>Ethnicity*</Typography>

                <Controller
                  name="ethnicity"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      // labelId="ethnicity-label"
                      id="ethnicity"
                      error={!!error}
                      onChange={onChange}
                      defaultValue="empty"
                      helperText={error ? error.message : null}
                    >
                      <MenuItem disabled value="empty">
                        -------
                      </MenuItem>
                      <MenuItem value="White - English / Welsh / Scottish / Northern Irish / British">
                        White - English / Welsh / Scottish / Northern Irish /
                        British
                      </MenuItem>
                      <MenuItem value="White - Irish">White - Irish</MenuItem>
                      <MenuItem value="White - Gypsy or Irish Traveller">
                        White - Gypsy or Irish Traveller
                      </MenuItem>
                      <MenuItem value="White and Black Caribbean">
                        White and Black Caribbean
                      </MenuItem>
                      <MenuItem value="White and Black African">
                        White and Black African
                      </MenuItem>
                      <MenuItem value="White and Asian">
                        White and Asian
                      </MenuItem>
                      <MenuItem value="Indian">Indian</MenuItem>
                      <MenuItem value="Pakistani">Pakistani</MenuItem>
                      <MenuItem value="Bangladeshi">Bangladeshi</MenuItem>
                      <MenuItem value="Chinese">Chinese</MenuItem>
                      <MenuItem value="African">African</MenuItem>
                      <MenuItem value="Caribbean">Caribbean</MenuItem>
                      <MenuItem value="Arab">Arab</MenuItem>
                      <MenuItem value="None of the above, my ethnicity is...">
                        None of the above, my ethnicity is...
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            {showEthnicityText == "None of the above, my ethnicity is..." && (
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Typography>Ethnicity</Typography>

                  <Controller
                    name="ethnicityText"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="ethnicityText"
                        // label="Where do you live?"
                        name="ethnicityText"
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <Typography>Where do you live?</Typography>

                <Controller
                  name="location"
                  control={control}
                  defaultValue=""
                  // rules={{ required: "First name required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      // error={!!error}
                      id="location"
                      // label="Where do you live?"
                      name="location"
                      onChange={onChange}
                      helperText="Please enter the Greater Manchester borough, and the first part of your postcode. E.g. 'Bolton, BL1', or 'Manchester, M22'."
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {/* <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <Typography>Email address</Typography>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="email"
                      name="email"
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>
            </Grid> */}
            <Grid item align="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                style={{ fontSize: "1.2rem" }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  };

  const LandingPage = () => {
    return (
      <>
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          style={{ marginTop: 10 }}
          direction="column"
        >
          <Grid item md={8}>
            <Typography
              align="center"
              // color="textSecondary"
              variant="h6"
              style={{ marginTop: 20, marginBottom: 0 }}
            >
              We’re collecting recordings of people from all over Greater
              Manchester to find out more about how people speak in the region.
              <br />
              <br />
              If you choose to take part, you will be asked to provide some
              basic biographical information before completing an online consent
              form (if you are under 16, you will also need to ask a parent or
              carer to complete a form).
              <br />
              <br />
              Please click below to get started.
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              // spacing={5}
              justify="center"
              alignItems="center"
              // style={{ marginTop: 20 }}
              direction="row"
            >
              <Grid item md={6}>
                <Button
                  // variant="contained"
                  // color="primary"
                  onClick={() => {
                    loadQuestion("over16");
                  }}
                >
                  <img src={BtnOver} alt="" />
                </Button>
              </Grid>

              <Grid item md={6}>
                <Button
                  onClick={() => {
                    loadQuestion("under16");
                  }}
                >
                  <img src={BtnUnder} alt="" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const PreExperience = () => {
    return (
      <>
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          style={{ marginTop: 10 }}
          direction="column"
        >
          <Grid item xs={10} sm={5}>
            <img src={MapImage} width="100%" alt="" />
          </Grid>
          <Grid item>
            <Typography
              align="center"
              // color="textSecondary"
              variant="h5"
              style={{
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              Thank you.
            </Typography>
            <Typography
              align="center"
              // color="textSecondary"
              variant="h6"
              style={
                {
                  // marginBottom: 10,
                  // textTransform: "uppercase",
                }
              }
            >
              To take part in our research, <br /> please press the record
              button below.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              style={{
                width: 120,
                height: 120,
                // backgroundColor: "red",
              }}
              // variant="contained"
              // color="default"
              // type="submit"
              // endIcon={<MicIcon />}
              onClick={() => {
                //setUserInfo(data);
                StartRecording();
              }}
            >
              <img src={BtnRecord} width={120} />
              {/* <MicIcon style={{ width: 100, height: 100, color: "white" }} /> */}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const PostExperience = () => {
    return (
      <>
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
          spacing={5}
          style={{ marginTop: 50 }}
        >
          <Grid item xs={12} style={{ width: 300 }}>
            {uploadCompleted ? (
              <>
                <Typography align="center">Complete!</Typography>
                <BorderLinearProgress variant="determinate" value={100} />
              </>
            ) : (
              <>
                <Typography align="center">Processing...</Typography>

                <BorderLinearProgress />
              </>
            )}
          </Grid>
          <Grid item>
            <Typography
              component="h3"
              variant="h6"
              align="center"
              color="textPrimary"
              // gutterBottom
            >
              Thank you for completing the survey.
            </Typography>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              // disabled={!uploadCompleted}
              onClick={() => {
                window.open("https://www.manchestervoices.org/", "_blank");
              }}
              style={{ width: 350 }}
            >
              Manchester Voices homepage
            </Button>
          </Grid>
          <Grid item>
            <img src={MapImage} width={300} alt="" />
          </Grid>
        </Grid>
      </>
    );
  };

  const PicturePage = () => {
    return (
      <>
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
          spacing={5}
          style={{ marginTop: 10 }}
        >
          <Grid item sm={10}>
            <Typography
              component="h3"
              variant="h6"
              align="center"
              color="textPrimary"
              // gutterBottom
            >
              The dotted line on the image shows a route from ‘start’ to
              ‘finish’ which goes around a series of items. Imagine that you are
              describing this route to someone who has a copy of the image, but
              with no dotted line. For example, you might start by saying ‘go
              down from the start flag and loop under the boat on a lake, and
              then go above the long alley…’.
            </Typography>
            <br />
            <Typography
              component="h3"
              variant="h6"
              align="center"
              color="textPrimary"
              // gutterBottom
            >
              Provide as much detail as you think they would need to
              successfully replicate the dotted line. Keep an eye out for some
              of the unusual things you’ll find along the route!
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Typography
              component="h3"
              variant="h6"
              align="center"
              color="textPrimary"
              // gutterBottom
            >
              Scroll down to see the complete map
            </Typography>
          </Grid>
          <Grid
            item
            style={{ border: "1px solid rgba(0,0,0,0.5)", borderRadius: 5 }}
          >
            <img src={DirectionPicture} width={"100%"} alt="" />
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Typography
              component="h3"
              variant="h6"
              align="center"
              color="textPrimary"
              // gutterBottom
            >
              Press to stop recording and submit answers
            </Typography>
          </Grid>

          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              style={{ width: 200, height: 60, fontSize: "1.2rem" }}
              // disabled={currentQuestionIndex == questions.length - 1}
              onClick={() => {
                StopRecording();
                setExperienceState(5);
                playAudio("voices/Thanks.mp3");
              }}
            >
              Finish
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const Stage = () => {
    let view = null;
    switch (experienceState) {
      case 0:
        view = <LandingPage />;
        break;
      case 1:
        view = <FormTemplate />;
        break;
      case 2:
        view = <PreExperience />;
        break;
      case 3:
        view = <QuestionsTemplate />;
        break;
      case 4:
        view = <PicturePage />;
        break;
      case 5:
        view = <PostExperience />;
        break;
      default:
        break;
    }
    return view;
  };

  return (
    <Container maxWidth="lg">
      <ReactPlayer
        url={currentQuestionAudio}
        playing={isQuestionAudioPlaying}
        playsinline={true}
        ref={questionAudioPlayerRef}
        // controls={true}
        style={{ display: "none" }}
        onEnded={() => setIsQuestionAudioPlaying(false)}
      />
      <Grid container justify="center" alignItems="center" direction="column">
        <Grid item>
          <img src={MVLogo} width={300} alt="" />
        </Grid>
        <Grid
          item
          style={{
            display:
              experienceState == 3 || experienceState == 4 ? "flex" : "none",
            marginTop: 40,
          }}
        >
          <Grid container direction="column" justify="center">
            <Grid item align="center">
              <img width={30} src={RecordIndicator} alt="" />
            </Grid>
            <Grid item>
              <Typography
                // component="h5"
                variant="h6"
                align="center"
                // color="textPrimary"
                gutterBottom
                style={{ textTransform: "uppercase", fontWeight: "bold" }}
              >
                Time recorded:
                <Timer
                  ref={recordingTimerRef}
                  startImmediately={false}
                  formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
                >
                  <Timer.Minutes />
                  :
                  <Timer.Seconds />
                </Timer>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Stage />
      {experienceState < 2 && (
        <Grid container justify="center" spacing={5} style={{ marginTop: 100 }}>
          <Grid item>
            <img width={150} src={LogoMMW} alt="" />
          </Grid>
          <Grid item>
            <img width={150} src={LogoUKRI} alt="" />
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
