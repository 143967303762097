import logo from "./logo.svg";
import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useMemo, useRef } from "react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  Button,
  Card,
  Grid,
  Container,
  Modal,
  Typography,
  IconButton,
  TextField,
  LinearProgress,
  FormControl,
  FormControlLabel,
  Checkbox,
  MobileStepper,
} from "@material-ui/core";
//firebase
import { FirebaseAppProvider, useAuth } from "reactfire";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { useFirestoreDocData, useFirestore } from "reactfire";

import Experience from "./components/Experience";
import Backend from "./components/Backend";
import { AppBar } from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3e6faa",
    },
    secondary: {
      main: "#c0242a",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 10,
        minWidth: 150,
        paddingLeft: 40,
        paddingRight: 40,
      },
    },
  },
});

var firebaseConfig = {
  apiKey: "AIzaSyC_D0DX_oykoITyIZbS22mDzzyOpa5_MvQ",
  authDomain: "manchester-accent-van.firebaseapp.com",
  projectId: "manchester-accent-van",
  storageBucket: "manchester-accent-van.appspot.com",
  messagingSenderId: "118740690308",
  appId: "1:118740690308:web:4776ad210c2b2b23f569d1",
  measurementId: "G-5E0M4T3Z2Q",
};

function App() {
  const classes = useStyles();
  const [openHelpModal, setOpenHelpModal] = useState(false);

  const handleHelpModal = () => {
    setOpenHelpModal(false);
  };
  return (
    <MuiThemeProvider theme={theme}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <div className={classes.root}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setOpenHelpModal(true)}
          >
            {/* <ContactSupportIcon fontSize="large" /> */}
          </IconButton>

          <Router>
            <Switch>
              <Route path="/admin">
                <Backend />
              </Route>
              <Route path="/">
                <Experience />
              </Route>
            </Switch>
          </Router>
          {/* Help modal */}

          {/* <Modal
        open={false}
        onClose={handleHelpModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        HELP?
      </Modal> */}
        </div>
      </FirebaseAppProvider>
    </MuiThemeProvider>
  );
}

export default App;
